import React, { useState, useContext } from 'react';
import UserContext from './context/UserContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import './css/components.css';
import Submitting from './Submitting';

export default function TrainingTableRowDelete ({ item, refreshTrainingList }) {
    const { user } = useContext(UserContext);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [sendingRequest, setSendingRequest] = useState(false);

    const sendDeleteRequest = async () => {
        const payload = {
            training_id: item.training_id,
            user_name: user.email
        };

        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_delete_training',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        ).then(response => console.log(response))
        .catch((e) => console.log(e))
    }

    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleConfirmDeleteModal = async () => {
        setSendingRequest(true);
        await sendDeleteRequest();
        refreshTrainingList();
        handleCloseDeleteModal();
        setSendingRequest(false);
    }

    return (
        <>
            <a >
                <i className="fa-solid fa-trash note__delete" aria-hidden="true" onClick={handleShowDeleteModal}></i>
            </a>
            <Modal 
                show={showDeleteModal} 
                onHide={handleCloseDeleteModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Please confirm deletion:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        sendingRequest ?
                        <Submitting displayText={"Submitting delete request"} /> :
                        <>
                            <p>Are you sure you wish to delete <strong>{item.training_name}</strong>?</p>
                            <p>(Training ID: <strong>{item.training_id}</strong>)</p>
                            <p>This action cannot be undone.</p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal} disabled={sendingRequest}>
                        Cancel
                    </Button>
                    <Button variant="danger"onClick={handleConfirmDeleteModal} disabled={sendingRequest}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}