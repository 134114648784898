import React, { useState, useContext } from 'react';
import axios from 'axios';
import UserContext from './context/UserContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EncodeDecodeContext } from './context/EncodeDecodeContext';
import Loading from './Loading';
import Submitting from './Submitting';

export default function TrainingTableAdd({ refreshTrainingList, trainingCategories, mediaTypes }) {
    const { user } = useContext(UserContext);
    const { encodeInput } = useContext(EncodeDecodeContext);

    const [showAddModal, setShowAddModal] = useState(false);
    const [addName, setAddName] = useState("");
    const [addUrl, setAddUrl] = useState("");
    const [addCategory, setAddCategory] = useState(1);
    const [addMediaType, setAddMediaType] = useState(1);

    const [sendingRequest, setSendingRequest] = useState(false);

    const sendAddRequest = async () => {
        const payload = {
            training_name: encodeInput(addName),
            training_url: encodeInput(addUrl), 
            training_category_id: addCategory,
            media_type_id: addMediaType,
            user_name: user.email
        };
        console.log("payload: ", payload)
        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_training',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        ).then(response => console.log(response))
        .catch((e) => console.log(e))
    }

    const handleAddNameChange = (e) => setAddName(e.target.value);
    const handleAddUrlChange = (e) => setAddUrl(e.target.value);
    const handleAddCategoryChange = (e) => setAddCategory(e.target.value);
    const handleAddMediaTypeChange = (e) => setAddMediaType(e.target.value);
    const handleCloseAddModal = () => setShowAddModal(false);
    const handleShowAddModal = () => setShowAddModal(true);
    const handleConfirmAddModal = async () => {
        if (addName === "" || addUrl === "") {
            alert("Please fill out all fields.");
        } else {
            setSendingRequest(true);
            await sendAddRequest();
            refreshTrainingList();
            handleCloseAddModal();
            setSendingRequest(false);
        }
    }

    return (
        <>
            <button className='btn btn-primary' onClick={handleShowAddModal}>Add</button>
            <Modal 
                show={showAddModal} 
                onHide={handleCloseAddModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Please set desired values:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        sendingRequest ?
                        <Submitting displayText={"Submitting add training request"} /> :
                        <>
                            <label >Name:</label>
                            <input className='fill__width' value={addName} onChange={handleAddNameChange} />
                            
                            <label>URL:</label>
                            <input className='fill__width' value={addUrl} onChange={handleAddUrlChange} />
                        
                            <label>Training Category:</label>
                            <select className='fill__width' onChange={handleAddCategoryChange}>
                                {
                                    trainingCategories.map((category, index) => (<option key={index} value={category.training_category_id}>{category.training_category_name}</option>))
                                }
                            </select>

                            <label>Media Type:</label>
                            <select className='fill__width' onChange={handleAddMediaTypeChange}>
                                {
                                    mediaTypes.map((medium, index) => (<option key={index} value={medium.media_type_id}>{medium.media_type_name}</option>))
                                }
                            </select>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal} disabled={sendingRequest}>
                        Cancel
                    </Button>
                    <Button variant="danger"onClick={handleConfirmAddModal} disabled={sendingRequest}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}