// ServiceOrderLookup.jsx

import React, { useState, useContext, useEffect } from 'react';
import './css/appointments.css'; // Existing CSS
import './css/ServiceOrderLookup.css'; // Ensure you have a dedicated CSS file
import UserContext from './context/UserContext';
import ServiceOrderContext from './context/ServiceOrderContext';
import ServiceOrderTable from './ServiceOrderTable'; // Table component
import Loading from './Loading'; // Loading component
import ReactJoyride from 'react-joyride';
import { FaBullseye } from 'react-icons/fa'; // Import Bullseye icon

export default function ServiceOrderLookup() {
  // Access user from UserContext if needed
  const { user } = useContext(UserContext);

  // Access service orders from ServiceOrderContext
  const {
    serviceOrders,
    setServiceOrders,
    isBullseyeMode,
    setIsBullseyeMode,
  } = useContext(ServiceOrderContext);

  // State variables
  const [serviceOrderNumber, setServiceOrderNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // API details
  const API_ENDPOINT_ALL =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acumatica_open_so';
  const API_ENDPOINT_SPECIFIC =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so';
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Replace with your actual API key or use environment variables

  // Joyride steps
  const [joyrideSteps] = useState([
    {
      target: '.search-order-number',
      content: 'Enter a specific Service Order Number here and click Search.',
      disableBeacon: true,
    },
    {
      target: '.fetch-all-orders-button',
      content: 'Click here to fetch all open Service Orders.',
    },
    {
      target: '.service-order-search',
      content: 'Use this field to search within the fetched Service Orders.',
    },
    {
      target: '.service-order-row',
      content: 'Click a Service Order to manage its appointments.',
    },
  ]);

  // Initialize runTour based on localStorage
  const [runTour, setRunTour] = useState(() => {
    const hasCompletedTour = localStorage.getItem('hasCompletedServiceOrderLookupTour');
    return !hasCompletedTour;
  });

  // Callback to handle Joyride events
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === 'finished' || status === 'skipped') {
      setRunTour(false);
      localStorage.setItem('hasCompletedServiceOrderLookupTour', 'true');
    }
  };

  // Handle input change
  const handleServiceOrderNumberChange = (e) => {
    setServiceOrderNumber(e.target.value);
  };

  /**
   * Mapping function to normalize service order data.
   * It accounts for different field names in API responses.
   * @param {Object} order - The service order object from API response.
   * @returns {Object} - The normalized service order object.
   */
  const mapServiceOrder = (order) => {
    // Format address using the new field structure
    const formatAddress = (order) => {
      if (!order) return 'N/A';
      return `${order.AddressLine1 || ''} ${order.AddressLine2 || ''} ${order.City || ''} ${order.State || ''} ${
        order.PostalCode || ''
      }`.trim() || 'N/A';
    };

    return {
      serviceOrderNumber: order.serviceOrderNumber || 'N/A',
      date: order.Date || order.date || '', // Handle both 'Date' and 'date'
      customerName: order.customerName || 'N/A',
      details: order.description || order.details || 'N/A',
      customerOrder: order.customerOrder || '',
      // Updated address and department handling
      address: formatAddress(order),
      department: order.Department || order.BranchLocation || 'N/A'
    };
  };

  /**
   * Generic function to parse API response.
   * It handles both object and array structures.
   * @param {Object} data - The raw API response.
   * @returns {Array} - The array of service orders.
   */
  const parseServiceOrders = (data) => {
    let serviceOrdersArray = [];

    if (data.body) {
      try {
        const parsedBody = JSON.parse(data.body);
        console.log('parsedBody:', parsedBody); // Debugging log

        if (Array.isArray(parsedBody)) {
          // Response is a direct array
          serviceOrdersArray = parsedBody;
        } else if (parsedBody.serviceOrders && Array.isArray(parsedBody.serviceOrders)) {
          // Response contains a 'serviceOrders' array
          serviceOrdersArray = parsedBody.serviceOrders;
        } else {
          console.warn('Unexpected body structure:', parsedBody);
        }
      } catch (err) {
        console.error('Error parsing JSON body:', err);
      }
    } else if (data.data && Array.isArray(data.data)) {
      // Alternative field 'data' containing the array
      serviceOrdersArray = data.data;
    } else {
      console.warn('No body or data field in API response.');
    }

    console.log('serviceOrdersArray:', serviceOrdersArray); // Debugging log
    return serviceOrdersArray;
  };

  /**
   * Handles searching for a specific service order by number.
   */
  const handleSearch = async () => {
    // Extract the 6-digit number, removing the "FS" prefix if present
    const normalizedOrderNumber = serviceOrderNumber.startsWith('FS')
      ? serviceOrderNumber.slice(2)
      : serviceOrderNumber;

    // Log the normalized order number to debug
    console.log('Normalized Order Number:', normalizedOrderNumber);

    // Validation: Ensure it's a valid 6-digit format
    if (!/^\d{6}$/.test(normalizedOrderNumber)) {
      setError('Please enter a valid 6-digit service order number.');
      return;
    }
    setError(null);
    setLoading(true);

    try {
      // Make a POST request to the specific service order API
      const response = await fetch(API_ENDPOINT_SPECIFIC, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({ serviceOrderIDs: [normalizedOrderNumber] }), // Send as array
      });

      if (!response.ok) {
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data); // Debugging log

      // Parse the response body using the generic parser
      const serviceOrdersArray = parseServiceOrders(data);

      console.log('Parsed API Response Body:', serviceOrdersArray); // Debugging log

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        // Transform each service order using the mapping function
        const transformedServiceOrders = serviceOrdersArray.map((order) =>
          mapServiceOrder(order)
        );
        setServiceOrders(transformedServiceOrders);
      } else {
        setServiceOrders([]);
        setError('No service orders found for the given number.');
      }
    } catch (err) {
      console.error('Error fetching service order:', err);
      setError('Failed to load service order. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles fetching all open service orders.
   */
  const handleFetchAll = async () => {
    setError(null);
    setLoading(true);
    setIsBullseyeMode(false); // Hide PMWA column when doing "Fetch All"

    // Calculate the date 7 days ago
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    const formattedDate = sevenDaysAgo.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    try {
      // Make a GET request to fetch all open service orders
      const response = await fetch(`${API_ENDPOINT_ALL}?Date>=${formattedDate}`, {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response (Fetch All):', data);

      // Parse the response body using the generic parser
      const serviceOrdersArray = parseServiceOrders(data);

      console.log('Parsed API Response Body:', serviceOrdersArray); // Debugging log

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        // Transform each service order using the mapping function
        const transformedServiceOrders = serviceOrdersArray.map((order) =>
          mapServiceOrder(order)
        );
        setServiceOrders(transformedServiceOrders);
      } else {
        setServiceOrders([]);
        setError('No service orders found for the given criteria.');
      }
    } catch (err) {
      console.error('Error fetching service orders:', err);
      setError('Failed to load service orders. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Optionally refetch data when the component mounts
    if (serviceOrders.length === 0) {
      handleFetchAll();
    }
  }, []); // Empty dependency array to run only on mount

  // Same function as "Fetch All," but only set results with 'target' in the customerName
  const handleBullseyeButtonClick = async () => {
    setError(null);
    setLoading(true);
    setIsBullseyeMode(true); // Show PMWA column for target customers

    // Calculate the date 7 days ago, same as handleFetchAll
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    const formattedDate = sevenDaysAgo.toISOString().split('T')[0];

    try {
      const response = await fetch(`${API_ENDPOINT_ALL}?Date>=${formattedDate}`, {
        method: 'GET',
        headers: {
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response (Bullseye):', data);

      // ------------------------
      // IMPORTANT: parseServiceOrders might be your existing function that extracts
      // the actual array (data.data, data.body, etc.) from the API response. 
      // If so, call that function here, otherwise just filter data directly.
      // ------------------------
      const serviceOrdersArray = parseServiceOrders(data) || [];

      // Filter out only those whose customerName includes 'target' (case-insensitive).
      const filteredTargetOrders = serviceOrdersArray.filter((order) => {
        const name = (order.customerName || '').toLowerCase();
        return name.includes('target');
      });

      if (filteredTargetOrders.length > 0) {
        const transformedOrders = filteredTargetOrders.map((order) => mapServiceOrder(order));
        setServiceOrders(transformedOrders);
      } else {
        setServiceOrders([]);
        setError("No service orders found with 'target' in the customer name.");
      }
    } catch (err) {
      console.error('Error fetching bullseye (target) orders:', err);
      setError("Failed to load 'target' service orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="awf-content-spacing">
      {/* React Joyride Component */}
      <ReactJoyride
        steps={joyrideSteps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        disableScrolling={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <div className="awf-card">
        <div className="awf-cardHeader">
          <h2 className="awf-cardTitle">Service Order Lookup</h2>
        </div>
        <div className="awf-cardContent">
          <div className="awf-controls">
            <div className="awf-inputGroup">
              <label htmlFor="serviceOrderNumber" className="awf-label">
                Service Order Number
              </label>
              <div className="awf-inputWrapper">
                <span className="awf-prefix">FS</span>
                <input
                  type="text"
                  id="serviceOrderNumber"
                  className="awf-input search-order-number"
                  value={serviceOrderNumber}
                  onChange={handleServiceOrderNumberChange}
                  placeholder="000000"
                  maxLength="8"
                />
              </div>
            </div>
            <button
              className="awf-button search-button"
              onClick={handleSearch}
              disabled={loading}
            >
              Search
            </button>
            <div className="awf-divider">OR</div>
            <button
              className="awf-button fetch-all-orders-button"
              onClick={handleFetchAll}
              disabled={loading}
            >
              Fetch All Open Service Orders
            </button>

            {/* Updated Bullseye Button */}
            <button
              className="awf-button bullseye-button"
              onClick={handleBullseyeButtonClick}
              disabled={loading}
              title="Fetch only 'target' customers"
            >
              <FaBullseye className="bullseye-icon" size={32} />
            </button>
          </div>

          {error && <div className="awf-error">{error}</div>}
          {loading && <Loading componentName="Service Orders" />}

          {!loading && serviceOrders.length > 0 && (
            <ServiceOrderTable showPMWAColumn={isBullseyeMode} />
          )}

          {!loading && serviceOrders.length === 0 && !error && (
            <div className="awf-noResults">
              No service orders found for the given criteria.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
