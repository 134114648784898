import React, { createContext, useState, useEffect } from 'react';

// 1) Create the context
const ServiceOrderContext = createContext();

// 2) Create and export the provider
export function ServiceOrderProvider({ children }) {
  // A. Load/persist "serviceOrders"
  const [serviceOrders, setServiceOrders] = useState(() => {
    const savedOrders = localStorage.getItem('serviceOrders');
    return savedOrders ? JSON.parse(savedOrders) : [];
  });

  useEffect(() => {
    localStorage.setItem('serviceOrders', JSON.stringify(serviceOrders));
  }, [serviceOrders]);

  // B. Track bullseye mode
  const [isBullseyeMode, setIsBullseyeMode] = useState(false);

  // C. Provide these values to the context
  const value = {
    serviceOrders,
    setServiceOrders,
    isBullseyeMode,
    setIsBullseyeMode,
  };

  return (
    <ServiceOrderContext.Provider value={value}>
      {children}
    </ServiceOrderContext.Provider>
  );
}

// 3) Export default the context itself
export default ServiceOrderContext;
