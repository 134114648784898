import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Note from './Note';
import NoteCreate from './NoteCreate';
import { useHeightAdjust } from './context/HeightAdjustContext'
import Loading from './Loading';
import UserContext from './context/UserContext';
import { EncodeDecodeContext } from './context/EncodeDecodeContext';

export default function News() {
    const { user } = useContext(UserContext);
    const { encodeInput, decodeOutput } = useContext(EncodeDecodeContext);

    const [notes, setNotes] = useState([]);
    const [title_text, setTitle_text] = useState("");
    const [body_text, setBody_text] = useState("");

    const title_textHandler = (e) => {
        setTitle_text(e.target.value);
    };

    const body_textHandler = (e) => {
        setBody_text(e.target.value);
    };

    const getNews = async () => {
        const payload = JSON.stringify({ "limit": 3 });
        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_news',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY} },     
        ).then(response => {
            let news = JSON.parse(response.data.body);
            news.forEach((item) => {
                item.title_text = decodeOutput(item.title_text)
                item.body_text = decodeOutput(item.body_text)
            });
            setNotes(news);
        })
        .catch((e) => console.log(e))
    }

    const saveHandler = async () => {
        const payload = {
            title_text: encodeInput(title_text),
            body_text: encodeInput(body_text),
            created_by: user.user_id, 
        };

        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_news',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        ).then(response => console.log(response))
        .then(() => {
            setTitle_text("");
            setBody_text("");
            setNotes([]);
            getNews();
        })
        .catch((e) => console.log(e))
        
    };

    const deleteNews = async (article_ID) => {
        const payload = JSON.stringify({ 
            "article_id": article_ID,
            "username": user.email
         });
        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_delete_news',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } },     
        ).then(response => {
            console.log(response.data.body)
        })
        .catch((e) => console.log(e))
        setNotes([]);
        getNews();
    }

    const editNews = async (article_ID, title_text, body_text) => {
        const payload = JSON.stringify({ 
            "article_id": article_ID,
            "title_text": encodeInput(title_text),
            "body_text": encodeInput(body_text),
            "username": user.email
         });
        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_news',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } },     
        ).then(response => {
            console.log(response.data.body)
        })
        .catch((e) => console.log(e))
        setNotes([]);
        getNews();
    }
    
    useEffect(() => {
        getNews()
    }, []);

    useEffect(useHeightAdjust(), [notes]);

    return (
        <>
            <h1>News</h1>
            {
                notes.length === 0 ? 
                <Loading componentName={"News"}/> :
                <div>
                    
                    <div className=''>
                        {user.role_id >= 8 &&
                        <NoteCreate
                            title_textHandler={title_textHandler}
                            body_textHandler={body_textHandler}
                            saveHandler={saveHandler}
                            title_text={title_text}
                            body_text={body_text}
                        />}
                        {notes.map((note) => (
                            <Note
                                key={note.article_ID}
                                note={note}
                                deleteNews={deleteNews}
                                editNews={editNews}
                            />
                        ))}
                    </div>
                </div>
            }
        </>
    )
}