import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { callMsGraph } from './graph';

import UserContext from './components/context/UserContext';
import axios from 'axios';

import { useIsAuthenticated } from "@azure/msal-react";

const UserProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [user, setUser] = useState(null);
  //const [userPicture, setUserPicture] = useState(null);

  // When MS SSO status is changed
  
useEffect(() => {
  if (isAuthenticated) {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    const fetchUserDataAndPicture = async (accessToken) => {
      try {
        // Fetch user profile data
        const userData = await callMsGraph(accessToken, 'me');
        // Convert the binary picture data to a URL
        //const pictureBlob = await callMsGraph(accessToken, 'me/photo/$value');
        //const pictureUrl = URL.createObjectURL(pictureBlob);

        // Update state
        setGraphData(userData);
        //setUserPicture(pictureUrl);
      } catch (error) {
        console.error("Error fetching user data or picture:", error);
      }
    };

    instance.acquireTokenSilent(request)
      .then((response) => fetchUserDataAndPicture(response.accessToken))
      .catch((e) => {
        instance.acquireTokenPopup(request)
          .then((response) => fetchUserDataAndPicture(response.accessToken))
          .catch((error) => console.error("Error acquiring token:", error));
      });
  } else {
    if (user !== null) {
      setGraphData(null);
      //setUserPicture(null); // Clear user picture when not authenticated
    }
  }

  console.log(graphData);
}, [isAuthenticated]);
  
  // When the data returned from MS SSO is changed
  useEffect(() => {
    //console.log("useEffect triggered - graphData Changed to " + graphData);
    if(graphData !== null) {
      //console.log(graphData)
      // get DB data
      axios.get('https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_user_role?user_name='+graphData.userPrincipalName, 
        { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY} }).then(res => {
          console.log(res)
        setUser({
          role_id: res.data.role_id,
          role_name: res.data.role_name,
          user_id: res.data.user_id,
          email: graphData.userPrincipalName,
          display_name: graphData.displayName,
          microsoft_id: graphData.id
        });
      })
      .catch((e) => {
        console.log("axios get request failed, error caught:");
        console.log(e);
        if (e.response.data.error) {
          console.log("you got this far")
          if (e.response.data.error = "User not found") {
            // Attempt to create user
            console.log(e.response.data.error + " - attempting to create new user");
            axios.get('https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/create_user?user_name='+graphData.userPrincipalName, 
              { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }).then(res => {
                //console.log(res.data.message);
                if(res.data.message === "User created successfully") {
                  window.location.reload();
                }
              })
              .catch((e) => {
                if (e.response.data.error) console.log(e.response.data.error);
              })
          }
        }
      })
    } else {
      console.log("graphData is null");
    }
  }, [graphData]);

    return (
      <UserContext.Provider value={{ user }}>
          {children}
      </UserContext.Provider>
  );
};

export default UserProvider;