import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import ITAdminUserTableRow from './ITAdminUserTableRow';
import ITAdminUserTableSave from './ITAdminUserTableSave';
import Loading from './Loading';


export default function ITAdminUserTable() {

    const [userList, setUserList] = useState(null);
    const [roleList, setRoleList] = useState(null);
    const [editList, setEditList] = useState([]);
    const [deleteList, setDeleteList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortProperties, setSortProperties] = useState({columnkey: "user_id", descending: false});

    useEffect(() => {
        if(userList === null) {
            axios.get('https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_users', 
                { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } })
                .then(res => {
                //console.log(res.data.message);
                if(res.data.body && res.data.body !== undefined) {
                    //console.log(res.data.body);
                    setUserList(JSON.parse(res.data.body));
                }
                })
                .catch((e) => {
                    if (e.response && e.response.data.error) console.log(e.response.data.error);
                }
            )
        }
        if(roleList === null) {
            axios.get('https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_list_roles', 
                { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } })
                .then(res => {
                //console.log(res.data.message);
                if(res.data.body && res.data.body !== undefined) {
                    console.log(res.data.body);
                    setRoleList(JSON.parse(res.data.body));
                }
                })
                .catch((e) => {
                    if (e.response && e.response.data.error) console.log(e.response.data.error);
                }
            )
        }
    }, [userList, roleList])

    const handleEditList = (newEdit) => {
        const { user_id, role_id, shouldRemove } = newEdit;
        setEditList(prev => {
            const existingIndex = prev.findIndex((edit) => edit.user_id === user_id);

            if (shouldRemove) {
                if(existingIndex === -1) return prev;
                else return prev.filter((_, index) => index !== existingIndex);
            }

            if (existingIndex === -1) return [...prev, {user_id, role_id}];
            else return prev.map((edit, index) => index === existingIndex ? {user_id, role_id: parseInt(role_id)} : edit);
        })
    }

    const handleDeleteList = (deleteItemID) => {
        setDeleteList(prev => prev.includes(deleteItemID) ? prev.filter(id => id !== deleteItemID) : [...prev, deleteItemID]);
    }

    const handleCancelButton = () => {
        setEditList([]);
        setDeleteList([]);
    }

    const handleSaveButton = () => {

    }

    const getFilteredAndSortedData = () => {
        if (!userList) return [];

        // Filter data
        const filteredData = userList.filter(user => {
            return Object.values(user).some(value =>
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        // Sort data
        const { columnkey, descending } = sortProperties;
        const sortedData = filteredData.slice().sort((a, b) => {
            if (!columnkey) return 0;
            if (a[columnkey] < b[columnkey]) return descending ? 1 : -1;
            if (a[columnkey] > b[columnkey]) return descending ? -1 : 1;
            return 0;
        });

        return sortedData;
    };

    const sortedAndFilteredData = getFilteredAndSortedData();

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (e) => {
        const columnkey=e.target.getAttribute('columnkey');
        if (columnkey === sortProperties.columnkey) {
            setSortProperties(prevState => ({
                ...prevState,
                descending: !prevState.descending
            }))
        }
        else {
            setSortProperties({columnkey: columnkey, descending: false})
        }
    }

    const getSortSymbol = (columnkey) => {
        if (columnkey === sortProperties.columnkey) return sortProperties.descending ? "↓" : "↑";
        return null;
    }

    return (
        <div>
            <h4>User Management</h4>
            {
                userList === null ?
                <Loading componentName={"User Table"} /> :
                <div>
                    <div className='d-flex justify-content-between'>
                        <div className='col'>
                            <input type='text' placeholder='Search users...' value={searchTerm} onChange={handleSearchChange}/>
                        </div>
                        <div className='col'>
                            
                        </div>
                        <div className='col d-flex justify-content-end'>
                            <button 
                                type="button" 
                                className="btn btn-secondary" 
                                style={{marginRight:10 + 'px'}} 
                                disabled={deleteList.length === 0 && editList.length === 0}
                                onClick={handleCancelButton}
                            >
                                Cancel
                            </button>
                            
                            <ITAdminUserTableSave 
                                userList={userList} 
                                roleList={roleList} 
                                deleteList={deleteList} 
                                editList={editList} 
                                clearChangeLists={handleCancelButton}
                                setUserList={setUserList}
                            />
                            
                        </div>
                    </div>
                    
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th columnkey="user_id" onClick={handleSortChange}>User ID {getSortSymbol("user_id")}</th>
                                <th columnkey="user_name" onClick={handleSortChange}>User Name {getSortSymbol("user_name")}</th>
                                <th columnkey="role_name" onClick={handleSortChange}>Role Name{getSortSymbol("role_name")}</th>
                                <th columnkey="role_id" onClick={handleSortChange}>Role ID{getSortSymbol("role_id")}</th>
                                <th className='text-center'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedAndFilteredData.map((user, index) => (
                                <ITAdminUserTableRow 
                                    user={user} 
                                    key={index} 
                                    roleList={roleList}
                                    editFlag={editList.findIndex((edit) => edit.user_id === user.user_id) >= 0} 
                                    handleEditList={handleEditList}
                                    deleteFlag={deleteList.includes(user.user_id)} 
                                    handleDeleteList={handleDeleteList} 
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            

        </div>
    )
}