import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Spinner, Alert, Form, Button } from 'react-bootstrap';

function UFC() {
  const [invoices, setInvoices] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'RefNumber', direction: 'asc' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState(new Set());
  const [filter, setFilter] = useState('all'); // 'all' | 'noTargets' | 'targets'
  const [alreadyProcessed, setAlreadyProcessed] = useState([]); // NEW: holds already-processed RefNumbers

  // State variables for button loading states
  const [isMarking, setIsMarking] = useState(false);
  const [isUnmarking, setIsUnmarking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Ref for "Select All" checkbox to handle indeterminate state
  const selectAllRef = useRef(null);

  // API endpoints
  const API_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_ufc_for_print';

  const MARK_PRINT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_mark_ufc_for_print';

  // NEW: endpoint to check/process invoices
  const PROCESS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_process_ufc';

  // Function to fetch invoices
  const fetchInvoices = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Filter invoices that have Funded === 'N'
      const filteredInvoices = data.invoices.filter((invoice) => invoice.Funded === 'N');
      setInvoices(filteredInvoices);
      setLoading(false);

      // After successfully setting "invoices", check which ones are already processed
      const refNumbers = filteredInvoices.map((inv) => inv.RefNumber);
      await checkIfAlreadyProcessed(refNumbers); // NEW call
    } catch (err) {
      console.error('Error fetching UFC invoices:', err);
      setError(true);
      setLoading(false);
    }
  };

  // NEW: function to check if any of the given invoices have already been processed
  const checkIfAlreadyProcessed = async (invoiceRefNumbers) => {
    if (!invoiceRefNumbers || invoiceRefNumbers.length === 0) return;

    try {
      const response = await fetch(PROCESS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY,
        },
        body: JSON.stringify({
          invoices: invoiceRefNumbers,
          process: false, // false => checking on them, not actually processing
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // data.alreadyProcessed holds the array of RefNumbers that were previously processed
      if (data.alreadyProcessed && Array.isArray(data.alreadyProcessed)) {
        setAlreadyProcessed(data.alreadyProcessed);
      }
    } catch (err) {
      console.error('Error checking if invoices have been processed:', err);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Keep the "Select All" checkbox in sync
  useEffect(() => {
    if (selectAllRef.current) {
      const isIndeterminate =
        selectedInvoices.size > 0 && selectedInvoices.size < invoices.length;
      selectAllRef.current.indeterminate = isIndeterminate;
    }
  }, [selectedInvoices, invoices.length]);

  // Handle filter changes via radio buttons
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    // Optionally clear the selected set on filter change
    setSelectedInvoices(new Set());
  };

  // Step 1: Filter by 'targets', 'noTargets', or 'all'
  const filteredInvoices = React.useMemo(() => {
    if (filter === 'all') {
      return invoices;
    } else if (filter === 'noTargets') {
      return invoices.filter(
        (inv) =>
          !inv.CustomerName ||
          !inv.CustomerName.toLowerCase().includes('target')
      );
    } else if (filter === 'targets') {
      return invoices.filter(
        (inv) =>
          inv.CustomerName &&
          inv.CustomerName.toLowerCase().includes('target')
      );
    }
    return invoices;
  }, [invoices, filter]);

  // Step 2: Sort the filtered set
  const sortedInvoices = React.useMemo(() => {
    let sortableItems = [...filteredInvoices];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        const valA = a[sortConfig.key];
        const valB = b[sortConfig.key];

        // Handle null or undefined
        if (valA == null) return 1;
        if (valB == null) return -1;

        // String comparison
        if (typeof valA === 'string' && typeof valB === 'string') {
          return valA.localeCompare(valB);
        }

        // Numeric comparison
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        }

        // Fallback comparison
        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
      });
      if (sortConfig.direction === 'desc') {
        sortableItems.reverse();
      }
    }
    return sortableItems;
  }, [filteredInvoices, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' 🔼' : ' 🔽';
    }
    return '';
  };

  // Select/Deselect All in table header
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allRefNumbers = sortedInvoices.map((inv) => inv.RefNumber);
      setSelectedInvoices(new Set(allRefNumbers));
    } else {
      setSelectedInvoices(new Set());
    }
  };

  // Toggle an individual row checkbox
  const handleSelectOne = (e, refNumber) => {
    const newSelected = new Set(selectedInvoices);
    if (e.target.checked) {
      newSelected.add(refNumber);
    } else {
      newSelected.delete(refNumber);
    }
    setSelectedInvoices(newSelected);
  };

  // Are all visible rows selected?
  const isAllSelected =
    sortedInvoices.length > 0 &&
    selectedInvoices.size === sortedInvoices.length;

  // Calculate the sum of balances for selected invoices
  const sumOfBalances = React.useMemo(() => {
    return sortedInvoices
      .filter((inv) => selectedInvoices.has(inv.RefNumber))
      .reduce((sum, inv) => sum + (parseFloat(inv.Balance) || 0), 0);
  }, [sortedInvoices, selectedInvoices]);

  // Function to mark/unmark invoices for print
  const handleMarkUnmarkForPrint = async (markForPrint) => {
    if (selectedInvoices.size === 0) return;

    // Set the corresponding loading state
    if (markForPrint) {
      setIsMarking(true);
    } else {
      setIsUnmarking(true);
    }

    try {
      const response = await fetch(MARK_PRINT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY,
        },
        body: JSON.stringify({
          invoices: Array.from(selectedInvoices),
          markForPrint: markForPrint,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      console.log(
        markForPrint
          ? 'Mark for Print successful for invoices:'
          : 'Unmark for Print successful for invoices:',
        result
      );

      // Refresh the table to reflect changes
      await fetchInvoices();

      // Optionally, maintain selection or update based on response
      // For this example, we'll keep the selection intact
      // If you prefer to clear, uncomment the next line
      // setSelectedInvoices(new Set());
    } catch (err) {
      console.error(
        markForPrint
          ? 'Error marking invoices for print:'
          : 'Error unmarking invoices for print:',
        err
      );
      alert(
        markForPrint
          ? 'Failed to mark invoices for print. Please try again.'
          : 'Failed to unmark invoices for print. Please try again.'
      );
    } finally {
      if (markForPrint) {
        setIsMarking(false);
      } else {
        setIsUnmarking(false);
      }
    }
  };

  // Function to process invoices
  const handleProcess = async () => {
    if (selectedInvoices.size === 0) return;

    setIsProcessing(true);
    try {
      const response = await fetch(PROCESS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY,
        },
        body: JSON.stringify({
          invoices: Array.from(selectedInvoices),
          process: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      console.log('Process invoices response:', data);
      alert('Invoices processed successfully.');

      // Refresh the table to reflect changes
      await fetchInvoices();
    } catch (err) {
      console.error('Error processing invoices:', err);
      alert('Failed to process invoices. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Helper function to format boolean fields
  const formatBoolean = (value) => (value === true ? 'Y' : 'N');

  // Helper function to format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  return (
    <div className="container mt-4">
      <h2>UFC Invoices</h2>

      {/* Filter and Action Buttons Row */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Filters Row */}
        <div className="d-flex flex-column w-50">
          <div>
            <Form.Check
              type="radio"
              inline
              label="All"
              name="filterRadio"
              id="radio-all"
              value="all"
              checked={filter === 'all'}
              onChange={handleFilterChange}
            />
            <Form.Check
              type="radio"
              inline
              label="No Targets"
              name="filterRadio"
              id="radio-no-targets"
              value="noTargets"
              checked={filter === 'noTargets'}
              onChange={handleFilterChange}
            />
            <Form.Check
              type="radio"
              inline
              label="Targets"
              name="filterRadio"
              id="radio-targets"
              value="targets"
              checked={filter === 'targets'}
              onChange={handleFilterChange}
            />
          </div>
        </div>

        {/* Action Buttons and Selected Info */}
        <div className="d-flex align-items-center" style={{ gap: '0.5rem' }}>
          {/* Selected Count and Sum */}
          {selectedInvoices.size > 0 && (
            <div className="me-3">
              <strong>Selected: {selectedInvoices.size}</strong>
              <br />
              <strong>Sum of Balances: {formatCurrency(sumOfBalances)}</strong>
            </div>
          )}

          {/* Action Buttons */}
          <div className="d-flex align-items-center" style={{ gap: '0.5rem' }}>
            <Button
              variant="secondary"
              onClick={() => handleMarkUnmarkForPrint(true)}
              disabled={isMarking || selectedInvoices.size === 0}
            >
              {isMarking ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Marking...
                </>
              ) : (
                'Mark for Print'
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleMarkUnmarkForPrint(false)}
              disabled={isUnmarking || selectedInvoices.size === 0}
            >
              {isUnmarking ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Unmarking...
                </>
              ) : (
                'Unmark for Print'
              )}
            </Button>
            <Button
              variant="primary"
              onClick={handleProcess}
              disabled={isProcessing || selectedInvoices.size === 0}
            >
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Processing...
                </>
              ) : (
                'Process'
              )}
            </Button>
          </div>
        </div>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ width: '50px', textAlign: 'center' }}>
              {/* "no-checkmark" to remove the check graphic but keep color fill */}
              <Form.Check
                type="checkbox"
                label=""
                id="select-all-checkbox"
                ref={selectAllRef}
                checked={isAllSelected}
                onChange={handleSelectAll}
                className="no-checkmark"
              />
            </th>
            <th
              onClick={() => requestSort('RefNumber')}
              style={{ cursor: 'pointer' }}
            >
              RefNumber{renderSortIcon('RefNumber')}
            </th>
            <th
              onClick={() => requestSort('CustomerName')}
              style={{ cursor: 'pointer' }}
            >
              CustomerName{renderSortIcon('CustomerName')}
            </th>
            <th
              onClick={() => requestSort('CreatedDate')}
              style={{ cursor: 'pointer' }}
            >
              CreatedDate{renderSortIcon('CreatedDate')}
            </th>
            <th onClick={() => requestSort('Amount')} style={{ cursor: 'pointer' }}>
              Amount{renderSortIcon('Amount')}
            </th>
            <th
              onClick={() => requestSort('Balance')}
              style={{ cursor: 'pointer' }}
            >
              Balance{renderSortIcon('Balance')}
            </th>
            <th onClick={() => requestSort('Funded')} style={{ cursor: 'pointer' }}>
              Funded{renderSortIcon('Funded')}
            </th>
            <th
              onClick={() => requestSort('SummaryBilling')}
              style={{ cursor: 'pointer' }}
            >
              Sum{renderSortIcon('SummaryBilling')}
            </th>
            <th
              onClick={() => requestSort('MarkForPrinting')}
              style={{ cursor: 'pointer' }}
            >
              Marked{renderSortIcon('MarkForPrinting')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedInvoices.map((invoice) => (
            <tr
              key={invoice.RefNumber}
              // NEW: highlight row if it has been previously processed
              style={
                alreadyProcessed.includes(invoice.RefNumber)
                  ? { backgroundColor: '#ffd0d0' }
                  : {}
              }
            >
              <td style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  label=""
                  id={`checkbox-${invoice.RefNumber}`}
                  checked={selectedInvoices.has(invoice.RefNumber)}
                  onChange={(e) => handleSelectOne(e, invoice.RefNumber)}
                  className="no-checkmark"
                />
              </td>
              <td>{invoice.RefNumber}</td>
              <td>{invoice.CustomerName}</td>
              <td>
                {invoice.CreatedDate
                  ? new Date(invoice.CreatedDate).toLocaleDateString()
                  : 'N/A'}
              </td>
              <td>{formatCurrency(invoice.Amount)}</td>
              <td>{formatCurrency(invoice.Balance)}</td>
              <td>{invoice.Funded}</td>
              <td
                className={invoice.SummaryBilling === true ? 'bg-warning' : ''}
              >
                {formatBoolean(invoice.SummaryBilling)}
              </td>
              <td>{formatBoolean(invoice.MarkForPrinting)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedInvoices.size > 0 && (
        <div className="mt-3">
          <strong>Selected Invoices: </strong>
          {Array.from(selectedInvoices).join(', ')}
        </div>
      )}

      {/* Inline style to remove the check graphic and adjust alignment */}
      <style>{`
        /* Remove any default check/tick graphic while retaining background color. */
        .no-checkmark .form-check-input[type="checkbox"]:checked {
          background-image: none !important;
          background-position: center !important;
        }

        /* Tweak vertical alignment so the box sits nicely on the line. */
        .no-checkmark .form-check-input[type="checkbox"] {
          position: relative;
          top: -1px; /* Adjust as needed for perfect alignment */
        }

        /* Optional: Adjust the cursor for disabled buttons */
        button:disabled {
          cursor: not-allowed;
        }

        /* Add some margin to the selected info */
        .me-3 {
          margin-right: 1rem !important;
        }
      `}</style>
    </div>
  );
}

export default UFC;
