// src/utils/fetchAppointments.js

export async function fetchAppointments(serviceOrderID) {
    const getAppointmentsEndpoint = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_appointments_for_so'; // Replace with your actual endpoint
  
  // Use environment variable for API Key (recommended)
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;

  try {
    console.log('Fetching appointments with ServiceOrderID:', serviceOrderID);

    const response = await fetch(getAppointmentsEndpoint, {
      method: 'POST', // Ensure this matches your API Gateway method
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY, // Include the API key here
      },
      body: JSON.stringify({
        ServiceOrderID: serviceOrderID,
      }),
    });

    console.log('Fetch response status:', response.status);

    if (!response.ok) {
      const errorBody = await response.text();
      console.error('Failed to fetch appointments:', response.status, errorBody);
      throw new Error('Failed to fetch appointments');
    }

    const data = await response.json();
    console.log('Raw Response:', data); // Log the raw response

    // Use data directly, no need to parse data.body
    if (!data.data) {
      console.error('Missing "data" property in response:', data);
      throw new Error('Invalid response structure from getAppointments API');
    }

    // Map over the appointments to ensure Duration is set
    const appointmentsWithDuration = data.data.map((appointment) => ({
      ...appointment,
      Duration: appointment.Duration !== null && appointment.Duration !== undefined ? appointment.Duration : 1,
    }));

    console.log('Appointments Data with Duration:', appointmentsWithDuration);
    return appointmentsWithDuration; // Returns an array of appointments with Duration
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
}