// src/components/DispatcherBoard.jsx

import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './css/DispatcherBoard.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

// Import Font Awesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList,
  faTruck,
  faSignOutAlt,
  faSignInAlt,
  faCheckCircle,
  faPauseCircle,
  faBan,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';

export default function DispatcherBoard() {
  const [state, setState] = useState({
    appointments: {},
    columns: {},
    columnOrder: [],
  });

  // State to store all appointments data
  const [allAppointmentsData, setAllAppointmentsData] = useState([]);

  // States for selected filters
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);
  const [selectedServiceOrders, setSelectedServiceOrders] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  // States to track filter collapse/expand
  const [showTechniciansFilter, setShowTechniciansFilter] = useState(false);
  const [showServiceOrdersFilter, setShowServiceOrdersFilter] = useState(false);
  const [showStatusesFilter, setShowStatusesFilter] = useState(false);
  const [showDepartmentsFilter, setShowDepartmentsFilter] = useState(false);

  // States for filter search inputs
  const [technicianSearch, setTechnicianSearch] = useState('');
  const [serviceOrderSearch, setServiceOrderSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');

  // Reference to prevent re-initializing filters
  const filtersInitialized = useRef(false);

  // Initialize useNavigate
  const navigate = useNavigate();

  // Function to get today's date in 'YYYY-MM-DD' format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Initialize selectedDate state
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = sessionStorage.getItem('selectedDate');
    return storedDate || getTodayDate();
  });

  // Update sessionStorage whenever selectedDate changes
  useEffect(() => {
    sessionStorage.setItem('selectedDate', selectedDate);
  }, [selectedDate]);

  // Fetch appointments when the component mounts or selectedDate changes
  useEffect(() => {
    fetchAppointments(selectedDate);
  }, [selectedDate]);

  // Calculate filter options using useMemo
  const {
    techniciansFilterOptions,
    serviceOrdersFilterOptions,
    statusesFilterOptions,
    departmentsFilterOptions,
  } = React.useMemo(() => {
    if (!allAppointmentsData) {
      return {
        techniciansFilterOptions: [],
        serviceOrdersFilterOptions: [],
        statusesFilterOptions: [],
        departmentsFilterOptions: [],
      };
    }

    const appointmentsArray = Array.isArray(allAppointmentsData)
      ? allAppointmentsData
      : [allAppointmentsData];

    const techniciansSet = new Set();
    const serviceOrdersSet = new Set();
    const statusesSet = new Set();
    const departmentsSet = new Set();

    appointmentsArray.forEach((appointment) => {
      let technician = appointment.TechnicianName || 'Unassigned';
      if (
        !technician ||
        technician.trim() === '' ||
        technician.toLowerCase() === 'acumatica'
      ) {
        technician = 'Unassigned';
      }

      techniciansSet.add(technician);
      serviceOrdersSet.add(appointment.ServiceOrderID.toString());
      const status = appointment.OnHold === 1 ? 'On Hold' : appointment.Status;
      statusesSet.add(status);
      departmentsSet.add(appointment.BranchLocation || 'Unassigned');
    });

    const techniciansArray = Array.from(techniciansSet);

    // Sort technicians alphabetically, placing 'Unassigned' at the top
    const sortedTechnicians = techniciansArray.sort((a, b) => {
      if (a === 'Unassigned') return -1;
      if (b === 'Unassigned') return 1;
      return a.localeCompare(b);
    });

    return {
      techniciansFilterOptions: sortedTechnicians,
      serviceOrdersFilterOptions: Array.from(serviceOrdersSet),
      statusesFilterOptions: Array.from(statusesSet),
      departmentsFilterOptions: Array.from(departmentsSet),
    };
  }, [allAppointmentsData]);

  // Initialize selected filters when filter options are available
  useEffect(() => {
    if (
      !filtersInitialized.current &&
      techniciansFilterOptions.length > 0 &&
      serviceOrdersFilterOptions.length > 0 &&
      statusesFilterOptions.length > 0 &&
      departmentsFilterOptions.length > 0
    ) {
      const savedTechnicians = sessionStorage.getItem('selectedTechnicians');
      const savedServiceOrders = sessionStorage.getItem('selectedServiceOrders');
      const savedStatuses = sessionStorage.getItem('selectedStatuses');
      const savedDepartments = sessionStorage.getItem('selectedDepartments');

      const parsedTechnicians = savedTechnicians ? JSON.parse(savedTechnicians) : null;
      const parsedServiceOrders = savedServiceOrders ? JSON.parse(savedServiceOrders) : null;
      const parsedStatuses = savedStatuses ? JSON.parse(savedStatuses) : null;
      const parsedDepartments = savedDepartments ? JSON.parse(savedDepartments) : null;

      setSelectedTechnicians(
        parsedTechnicians && parsedTechnicians.length > 0
          ? parsedTechnicians
          : [...techniciansFilterOptions]
      );

      setSelectedServiceOrders(
        parsedServiceOrders && parsedServiceOrders.length > 0
          ? parsedServiceOrders
          : [...serviceOrdersFilterOptions]
      );

      setSelectedStatuses(
        parsedStatuses && parsedStatuses.length > 0
          ? parsedStatuses
          : [...statusesFilterOptions]
      );

      setSelectedDepartments(
        parsedDepartments && parsedDepartments.length > 0
          ? parsedDepartments
          : [...departmentsFilterOptions]
      );

      filtersInitialized.current = true; // Mark filters as initialized
    } else {
      // When filter options change, ensure that selected filters include any new options
      setSelectedTechnicians((prevSelectedTechnicians) => {
        const stillValid = prevSelectedTechnicians.filter((tech) =>
          techniciansFilterOptions.includes(tech)
        );
        const newlyAdded = techniciansFilterOptions.filter(
          (tech) => !stillValid.includes(tech)
        );
        return [...stillValid, ...newlyAdded];
      });

      setSelectedServiceOrders((prevSelectedServiceOrders) => {
        const stillValid = prevSelectedServiceOrders.filter((order) =>
          serviceOrdersFilterOptions.includes(order)
        );
        const newlyAdded = serviceOrdersFilterOptions.filter(
          (order) => !stillValid.includes(order)
        );
        return [...stillValid, ...newlyAdded];
      });

      setSelectedStatuses((prevSelectedStatuses) => {
        const stillValid = prevSelectedStatuses.filter((status) =>
          statusesFilterOptions.includes(status)
        );
        const newlyAdded = statusesFilterOptions.filter(
          (status) => !stillValid.includes(status)
        );
        return [...stillValid, ...newlyAdded];
      });

      setSelectedDepartments((prevSelectedDepartments) => {
        const stillValid = prevSelectedDepartments.filter((dept) =>
          departmentsFilterOptions.includes(dept)
        );
        const newlyAdded = departmentsFilterOptions.filter(
          (dept) => !stillValid.includes(dept)
        );
        return [...stillValid, ...newlyAdded];
      });
    }
  }, [
    techniciansFilterOptions,
    serviceOrdersFilterOptions,
    statusesFilterOptions,
    departmentsFilterOptions,
    filtersInitialized,
  ]);

  // Re-process appointments whenever filters change
  useEffect(() => {
    processAppointments(allAppointmentsData);
  }, [
    selectedTechnicians,
    selectedServiceOrders,
    selectedStatuses,
    selectedDepartments,
    allAppointmentsData,
  ]);

  // Save selected technicians to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      'selectedTechnicians',
      JSON.stringify(selectedTechnicians)
    );
  }, [selectedTechnicians]);

  // Save selected service orders to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      'selectedServiceOrders',
      JSON.stringify(selectedServiceOrders)
    );
  }, [selectedServiceOrders]);

  // Save selected statuses to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      'selectedStatuses',
      JSON.stringify(selectedStatuses)
    );
  }, [selectedStatuses]);

  // Save selected departments to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      'selectedDepartments',
      JSON.stringify(selectedDepartments)
    );
  }, [selectedDepartments]);

  // Function to fetch appointments from the API
  const [loading, setLoading] = useState(true);

  const fetchAppointments = async (date) => {
    // Set loading to true before fetching
    setLoading(true);

    const apiUrl =
      'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_appointments_by_date';
    const apiKey = process.env.REACT_APP_CONEXCS_API_KEY;
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    };
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({ AppointmentDate: date }),
      });
      const data = await response.json();

      // Extract appointments data
      const appointmentsData = data.data;

      // Store all appointments data
      setAllAppointmentsData(appointmentsData);

      // Process appointments
      processAppointments(appointmentsData);

      // Set loading to false after processing
      setLoading(false);
    } catch (error) {
      console.error('Error fetching appointments:', error);

      // Set loading to false even if there's an error
      setLoading(false);
    }
  };

  // Function to determine the color class based on Status and OnHold
  const getStatusColorClass = (appointment) => {
    if (appointment.OnHold === 1) {
      return 'pastel-yellow';
    }

    switch (appointment.Status) {
      case 'Assigned':
        return 'pastel-blue';
      case 'Dispatched':
        return 'pastel-light-blue';
      case 'Depart':
        return 'pastel-teal';
      case 'Arrive':
        return 'pastel-light-green';
      case 'Complete':
        return 'pastel-green';
      case 'Canceled':
        return 'pastel-gray';
      default:
        return 'pastel-gray';
    }
  };

  // Function to get the Font Awesome icon based on Status and OnHold
  const getStatusIcon = (appointment) => {
    if (appointment.OnHold === 1) {
      return faPauseCircle;
    }

    switch (appointment.Status) {
      case 'Assigned':
        return faClipboardList;
      case 'Dispatched':
        return faTruck;
      case 'Depart':
        return faSignOutAlt;
      case 'Arrive':
        return faSignInAlt;
      case 'Complete':
        return faCheckCircle;
      case 'Canceled':
        return faBan;
      default:
        return faClipboardList;
    }
  };

  const processAppointments = (appointmentsData) => {
    if (!appointmentsData) return;

    let appointmentsArray = Array.isArray(appointmentsData)
      ? appointmentsData
      : [appointmentsData];

    // Apply filters
    const filteredAppointmentsArray = appointmentsArray.filter((appointment) => {
      let technician = appointment.TechnicianName || 'Unassigned';
      if (
        !technician ||
        technician.trim() === '' ||
        technician.toLowerCase() === 'acumatica'
      ) {
        technician = 'Unassigned';
      }

      const status = appointment.OnHold === 1 ? 'On Hold' : appointment.Status;

      const matchesTechnician =
        selectedTechnicians.length === 0
          ? false
          : selectedTechnicians.includes(technician);
      const matchesServiceOrder =
        selectedServiceOrders.length === 0
          ? false
          : selectedServiceOrders.includes(appointment.ServiceOrderID.toString());
      const matchesStatus =
        selectedStatuses.length === 0
          ? false
          : selectedStatuses.includes(status);
      const matchesDepartment =
        selectedDepartments.length === 0
          ? false
          : selectedDepartments.includes(appointment.BranchLocation || 'Unassigned');

      return (
        matchesTechnician &&
        matchesServiceOrder &&
        matchesStatus &&
        matchesDepartment
      );
    });

    const appointments = {};
    filteredAppointmentsArray.forEach((appointment) => {
      let technician = appointment.TechnicianName || 'Unassigned';
      if (
        !technician ||
        technician.trim() === '' ||
        technician.toLowerCase() === 'acumatica'
      ) {
        technician = 'Unassigned';
      }

      const colorClass = getStatusColorClass(appointment);
      const statusIcon = getStatusIcon(appointment);

      appointments[`app-${appointment.ID}`] = {
        id: `app-${appointment.ID}`,
        content: `${appointment.ServiceOrderID}-${appointment.AppointmentNumber}`,
        technicianName: technician,
        colorClass,
        statusIcon,
        CustName: appointment.CustomerName || 'No Customer',
        AppDescription: appointment.Description || 'No Description',
        CustAddress: appointment.Address || 'No Address',
        BranchLocation: appointment.BranchLocation || 'No Branch',
        Status: appointment.OnHold === 1 ? 'On Hold' : appointment.Status,
        ...appointment,
        ServiceOrderAppointmentID: `${appointment.ServiceOrderID}-${appointment.AppointmentNumber}`,
      };
    });

    // Group appointments by technician
    const technicians = {};
    filteredAppointmentsArray.forEach((appointment) => {
      let technician = appointment.TechnicianName || 'Unassigned';
      if (
        !technician ||
        technician.trim() === '' ||
        technician.toLowerCase() === 'acumatica'
      ) {
        technician = 'Unassigned';
      }

      if (!technicians[technician]) {
        technicians[technician] = [];
      }
      technicians[technician].push(`app-${appointment.ID}`);
    });

    // Ensure 'Unassigned' is always present
    if (!technicians['Unassigned']) {
      technicians['Unassigned'] = [];
    }

    // Sort appointments within each technician column by status priority
    /* const statusOrder = {
      'Canceled': 0,
      'Complete': 1,
      'Arrive': 2,
      'Depart': 3,
      'Dispatched': 4,
      'Assigned': 5,
      'On Hold': 6,
    };

    for (const technician in technicians) {
      technicians[technician].sort((aId, bId) => {
        const a = appointments[aId];
        const b = appointments[bId];

        let statusA = a.OnHold === 1 ? 'On Hold' : a.Status;
        let statusB = b.OnHold === 1 ? 'On Hold' : b.Status;

        const orderA = statusOrder[statusA] !== undefined ? statusOrder[statusA] : 7;
        const orderB = statusOrder[statusB] !== undefined ? statusOrder[statusB] : 7;

        return orderA - orderB;
      });
    }
 */
    // Create columns and columnOrder
    const columns = {};
    Object.keys(technicians).forEach((technician) => {
      const appointmentIds = technicians[technician];
      columns[technician] = {
        id: technician,
        title: technician,
        appointmentIds: appointmentIds,
      };
    });

    let columnOrder = Object.keys(columns);

    // Remove 'Unassigned' from columnOrder if it exists
    columnOrder = columnOrder.filter((tech) => tech !== 'Unassigned');

    // Sort the technicians alphabetically
    columnOrder.sort();

    // Prepend 'Unassigned' at the beginning if necessary
    if (columns.hasOwnProperty('Unassigned')) {
      columnOrder = ['Unassigned', ...columnOrder];
    }

    setState((prevState) => {
      if (
        JSON.stringify(prevState.appointments) !== JSON.stringify(appointments) ||
        JSON.stringify(prevState.columns) !== JSON.stringify(columns) ||
        JSON.stringify(prevState.columnOrder) !== JSON.stringify(columnOrder)
      ) {
        return {
          appointments,
          columns,
          columnOrder,
        };
      }
      return prevState;
    });
  };

  /**
   * 1) Helper to reorder all appointments in the backend after any drag event.
   *    We build a list of { ServiceOrderAppointmentID, SortOrder } from the
   *    final layout in newState, then POST it to your reorder endpoint.
   */
  const reorderAllAppointments = async (newState) => {
    try {
      // Build array of all updated sort orders
      const reorderPayload = [];

      for (const columnId of newState.columnOrder) {
        const column = newState.columns[columnId];
        column.appointmentIds.forEach((appId, index) => {
          const appt = newState.appointments[appId];
          reorderPayload.push({
            ServiceOrderAppointmentID: appt.ServiceOrderAppointmentID,
            SortOrder: index + 1, // or other numbering scheme
          });
        });
      }

      const reorderEndpoint = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_reorder_appointments';
      const apiKey = process.env.REACT_APP_CONEXCS_API_KEY;
      const headers = {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      };
      const response = await fetch(reorderEndpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify({ appointments: reorderPayload }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error reordering appointments:', errorText);
      } else {
        console.log('Sort order updated successfully in backend!');
      }
    } catch (err) {
      console.error('Exception in reorderAllAppointments:', err);
    }
  };

  /**
   * 2) Main onDragEnd function:
   *    - We do the same local-state updates as before.
   *    - If the appointment moves to a different technician, we also call updateAppointmentTechnician.
   *    - After local state is updated, we call reorderAllAppointments to persist the final order.
   */
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    // If there is no destination, do nothing
    if (!destination) {
      return;
    }

    // If the item is dropped in the same place, do nothing
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const appointment = state.appointments[draggableId];

    // Allow moving only if status is 'Assigned' or 'Dispatched'
    if (appointment.Status !== 'Assigned' && appointment.Status !== 'Dispatched') {
      alert(
        `Cannot move appointment with status "${appointment.Status}".\nOnly appointments with status "Assigned" or "Dispatched" can be reassigned.`
      );
      return;
    }

    const startColumn = state.columns[source.droppableId];
    const finishColumn = state.columns[destination.droppableId];

    // CASE A: Moving within the same column
    if (startColumn === finishColumn) {
      const newAppointmentIds = Array.from(startColumn.appointmentIds);
      newAppointmentIds.splice(source.index, 1);
      newAppointmentIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        appointmentIds: newAppointmentIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);

      // Reorder in DB after local state is updated
      await reorderAllAppointments(newState);
      return;
    }

    // CASE B: Moving to a different column
    const startAppointmentIds = Array.from(startColumn.appointmentIds);
    startAppointmentIds.splice(source.index, 1);

    const finishAppointmentIds = Array.from(finishColumn.appointmentIds);
    finishAppointmentIds.splice(destination.index, 0, draggableId);

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [startColumn.id]: {
          ...startColumn,
          appointmentIds: startAppointmentIds,
        },
        [finishColumn.id]: {
          ...finishColumn,
          appointmentIds: finishAppointmentIds,
        },
      },
    };

    setState(newState);

    // 1) Update the assigned technician in the DB
    await updateAppointmentTechnician(draggableId, finishColumn.title);

    // 2) Reorder everything in the DB so SortOrder is consistent
    await reorderAllAppointments(newState);
  };

  // Function to update the appointment's assigned technician in the backend
  const updateAppointmentTechnician = async (appointmentId, technicianName) => {
    const appointment = state.appointments[appointmentId];
    console.log('updateAppointmentTechnician called with:');
    console.log('appointmentId:', appointmentId);
    console.log('technicianName:', technicianName);
    console.log('appointment:', appointment);

    const appointmentID = appointment.ServiceOrderAppointmentID;
    console.log('appointmentID:', appointmentID);

    const getTechnicianEmail = (name) => {
      if (!name || name === 'Unassigned') {
        return 'acumatica@collins-cs.com';
      }
      const email = name
        .trim()
        .toLowerCase()
        .replace(/[^a-z\s]/g, '') // Remove non-alphabetic characters
        .replace(/\s+/g, '.'); // Replace spaces with dots
      return `${email}@collins-cs.com`;
    };

    const technicianEmail = getTechnicianEmail(technicianName);
    console.log('technicianEmail:', technicianEmail);

    if (!appointmentID) {
      console.error('appointmentID is undefined. Cannot proceed.');
      return;
    }

    if (!technicianEmail) {
      console.error(`Technician email not found for technician: ${technicianName}`);
      return;
    }

    const payload = {
      ServiceOrderAppointmentID: appointmentID,
      TechnicianEmail: technicianEmail,
    };

    console.log('Payload being sent:', JSON.stringify(payload));

    const apiUrl =
      'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment_details';
    const apiKey = process.env.REACT_APP_CONEXCS_API_KEY;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error updating technician: ${response.statusText} - ${errorText}`);
      }

      console.log(`Appointment ${appointmentID} updated to technician ${technicianName}`);
    } catch (error) {
      console.error('Error updating appointment technician:', error);
    }
  };

  // Function to handle double-clicking an appointment card
  const handleAppointmentDoubleClick = (appointment) => {
    navigate(`/service-orders/${appointment.ServiceOrderID}/appointments`);
  };

  // Filtered options based on search input
  const filteredTechnicians = techniciansFilterOptions.filter((tech) =>
    tech.toLowerCase().includes(technicianSearch.toLowerCase())
  );

  const filteredServiceOrders = serviceOrdersFilterOptions.filter((order) =>
    order.toString().toLowerCase().includes(serviceOrderSearch.toLowerCase())
  );

  const filteredStatuses = statusesFilterOptions.filter((status) =>
    status.toLowerCase().includes(statusSearch.toLowerCase())
  );

  const filteredDepartments = departmentsFilterOptions.filter((dept) =>
    dept.toLowerCase().includes(departmentSearch.toLowerCase())
  );

  // Function to reset all filters
  const resetFilters = () => {
    setSelectedTechnicians([...techniciansFilterOptions]);
    setSelectedServiceOrders([...serviceOrdersFilterOptions]);
    setSelectedStatuses([...statusesFilterOptions]);
    setSelectedDepartments([...departmentsFilterOptions]);

    setTechnicianSearch('');
    setServiceOrderSearch('');
    setStatusSearch('');
    setDepartmentSearch('');
    setShowTechniciansFilter(false);
    setShowServiceOrdersFilter(false);
    setShowStatusesFilter(false);
    setShowDepartmentsFilter(false);

    // Clear saved filters from sessionStorage
    sessionStorage.removeItem('selectedTechnicians');
    sessionStorage.removeItem('selectedServiceOrders');
    sessionStorage.removeItem('selectedStatuses');
    sessionStorage.removeItem('selectedDepartments');
  };

  const [hoveredAppointmentId, setHoveredAppointmentId] = useState(null);

  // Functions to select/deselect all visible technicians
  const selectAllTechnicians = () => {
    setSelectedTechnicians([...filteredTechnicians]);
  };
  const deselectAllTechnicians = () => {
    setSelectedTechnicians((prevSelected) =>
      prevSelected.filter((tech) => !filteredTechnicians.includes(tech))
    );
  };

  // Functions to select/deselect all visible service orders
  const selectAllServiceOrders = () => {
    setSelectedServiceOrders([...filteredServiceOrders]);
  };
  const deselectAllServiceOrders = () => {
    setSelectedServiceOrders((prevSelected) =>
      prevSelected.filter((order) => !filteredServiceOrders.includes(order))
    );
  };

  // Functions to select/deselect all visible statuses
  const selectAllStatuses = () => {
    setSelectedStatuses([...filteredStatuses]);
  };
  const deselectAllStatuses = () => {
    setSelectedStatuses((prevSelected) =>
      prevSelected.filter((status) => !filteredStatuses.includes(status))
    );
  };

  // Functions to select/deselect all visible departments
  const selectAllDepartments = () => {
    setSelectedDepartments([...filteredDepartments]);
  };
  const deselectAllDepartments = () => {
    setSelectedDepartments((prevSelected) =>
      prevSelected.filter((dept) => !filteredDepartments.includes(dept))
    );
  };

  return (
    <div className="dispatcher-board">
      <div className="filters">
        <div className="filters-row">
          {/* Left Section */}
          <div className="filters-left">
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />

            {/* Filters */}
            <div className="filter-group">
              {/* Technicians Filter */}
              <div className="filter-item">
                <div
                  className="filter-header"
                  onClick={() => setShowTechniciansFilter(!showTechniciansFilter)}
                >
                  <label>Technicians</label>
                  <FontAwesomeIcon icon={showTechniciansFilter ? faChevronUp : faChevronDown} />
                </div>
                {showTechniciansFilter && (
                  <div className="filter-options">
                    <input
                      type="text"
                      placeholder="Search technicians..."
                      value={technicianSearch}
                      onChange={(e) => setTechnicianSearch(e.target.value)}
                      className="filter-search"
                    />

                    <div className="select-buttons">
                      <button className="btn btn-sm btn-primary me-2" onClick={selectAllTechnicians}>
                        Select All
                      </button>
                      <button className="btn btn-sm btn-secondary" onClick={deselectAllTechnicians}>
                        Deselect All
                      </button>
                    </div>

                    {filteredTechnicians.map((tech) => (
                      <div key={tech}>
                        <input
                          type="checkbox"
                          value={tech}
                          checked={selectedTechnicians.includes(tech)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedTechnicians((prev) =>
                              prev.includes(value)
                                ? prev.filter((item) => item !== value)
                                : [...prev, value]
                            );
                          }}
                        />
                        <span>{tech}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Service Orders Filter */}
              <div className="filter-item">
                <div
                  className="filter-header"
                  onClick={() => setShowServiceOrdersFilter(!showServiceOrdersFilter)}
                >
                  <label>Service Orders</label>
                  <FontAwesomeIcon icon={showServiceOrdersFilter ? faChevronUp : faChevronDown} />
                </div>
                {showServiceOrdersFilter && (
                  <div className="filter-options">
                    <input
                      type="text"
                      placeholder="Search service orders..."
                      value={serviceOrderSearch}
                      onChange={(e) => setServiceOrderSearch(e.target.value)}
                      className="filter-search"
                    />

                    <div className="select-buttons">
                      <button className="btn btn-sm btn-primary me-2" onClick={selectAllServiceOrders}>
                        Select All
                      </button>
                      <button className="btn btn-sm btn-secondary" onClick={deselectAllServiceOrders}>
                        Deselect All
                      </button>
                    </div>

                    {filteredServiceOrders.map((order) => (
                      <div key={order}>
                        <input
                          type="checkbox"
                          value={order}
                          checked={selectedServiceOrders.includes(order)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedServiceOrders((prev) =>
                              prev.includes(value)
                                ? prev.filter((item) => item !== value)
                                : [...prev, value]
                            );
                          }}
                        />
                        <span>{order}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Statuses Filter */}
              <div className="filter-item">
                <div className="filter-header">
                  <div onClick={() => setShowStatusesFilter(!showStatusesFilter)}>
                    <label>Status</label>
                    <FontAwesomeIcon icon={showStatusesFilter ? faChevronUp : faChevronDown} />
                  </div>
                </div>
                {showStatusesFilter && (
                  <div className="filter-options">
                    <input
                      type="text"
                      placeholder="Search statuses..."
                      value={statusSearch}
                      onChange={(e) => setStatusSearch(e.target.value)}
                      className="filter-search"
                    />
                    <div className="select-buttons">
                      <button className="btn btn-sm btn-primary me-2" onClick={selectAllStatuses}>
                        Select All
                      </button>
                      <button className="btn btn-sm btn-secondary" onClick={deselectAllStatuses}>
                        Deselect All
                      </button>
                    </div>
                    {filteredStatuses.map((status) => (
                      <div key={status}>
                        <input
                          type="checkbox"
                          value={status}
                          checked={selectedStatuses.includes(status)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedStatuses((prev) =>
                              prev.includes(value)
                                ? prev.filter((item) => item !== value)
                                : [...prev, value]
                            );
                          }}
                        />
                        <span>{status}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Departments Filter */}
              <div className="filter-item">
                <div className="filter-header">
                  <div onClick={() => setShowDepartmentsFilter(!showDepartmentsFilter)}>
                    <label>Department</label>
                    <FontAwesomeIcon icon={showDepartmentsFilter ? faChevronUp : faChevronDown} />
                  </div>
                </div>
                {showDepartmentsFilter && (
                  <div className="filter-options">
                    <input
                      type="text"
                      placeholder="Search departments..."
                      value={departmentSearch}
                      onChange={(e) => setDepartmentSearch(e.target.value)}
                      className="filter-search"
                    />
                    <div className="select-buttons">
                      <button className="btn btn-sm btn-primary me-2" onClick={selectAllDepartments}>
                        Select All
                      </button>
                      <button className="btn btn-sm btn-secondary" onClick={deselectAllDepartments}>
                        Deselect All
                      </button>
                    </div>
                    {filteredDepartments.map((dept) => (
                      <div key={dept}>
                        <input
                          type="checkbox"
                          value={dept}
                          checked={selectedDepartments.includes(dept)}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedDepartments((prev) =>
                              prev.includes(value)
                                ? prev.filter((item) => item !== value)
                                : [...prev, value]
                            );
                          }}
                        />
                        <span>{dept}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="filters-right">
            <button
              type="button"
              onClick={() => resetFilters()}
              className="btn btn-primary"
            >
              Reset Filters
            </button>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        {loading ? (
          <p>Loading appointments...</p>
        ) : state.columnOrder.length > 0 ? (
          <div className="board-container">
            {/* Technicians Board */}
            <div className="technicians-board">
              {state.columnOrder.map((columnId, columnIndex) => {
                const column = state.columns[columnId];
                const appointments = column.appointmentIds.map(
                  (appointmentId) => state.appointments[appointmentId]
                );

                // Determine if this is the Unassigned row
                const isUnassigned = column.title === 'Unassigned';

                return (
                  <div
                    key={column.id}
                    className={`technician-row ${
                      isUnassigned ? 'unassigned-row' : ''
                    }`}
                    style={isUnassigned ? { marginBottom: '10px' } : {}}
                  >
                    {/* Technician Name Card */}
                    {!isUnassigned && (
                      <div className="technician-name-card">
                        <span className="technician-name-text">
                          {column.title}
                        </span>
                      </div>
                    )}

                    {/* Unassigned Label */}
                    {isUnassigned && (
                      <div className="unassigned-label">
                        <span className="unassigned-text">Unassigned</span>
                      </div>
                    )}

                    {/* Technician Appointments Container */}
                    <div className="technician-appointments-container">
                      <Droppable
                        droppableId={column.id}
                        direction="horizontal"
                      >
                        {(provided) => (
                          <div
                            className="appointment-row"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {appointments.map((appointment, index) => {
                              // 1) If "CustName" has "Target", display "Target " plus last 5 chars
                              // 2) If "CustName" has "Circle K", remove " - Billing : " portion
                              let displayName = appointment.CustName;

                              if (displayName && displayName.includes('Target')) {
                                displayName = `Target ${displayName.slice(-5)}`;
                              } else if (displayName && displayName.includes('Circle K')) {
                                // Replace variations of "- Billing : " (case-insensitive) with just a space
                                // e.g. "Circle K - Billing : 270339" => "Circle K 270339"
                                displayName = displayName.replace(/-+\s*Billing\s*:\s*/i, ' ');
                              }

                              return (
                                <Draggable
                                  key={appointment.id}
                                  draggableId={appointment.id}
                                  index={index}
                                  isDragDisabled={
                                    appointment.Status !== 'Assigned' &&
                                    appointment.Status !== 'Dispatched'
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`appointment-card ${
                                        appointment.colorClass
                                      } ${
                                        appointment.Status !== 'Assigned' &&
                                        appointment.Status !== 'Dispatched'
                                          ? 'non-draggable'
                                          : ''
                                      }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      onDoubleClick={() =>
                                        handleAppointmentDoubleClick(appointment)
                                      }
                                      style={{
                                        ...provided.draggableProps.style,
                                        width: `${appointment.Duration * 200}px`,
                                        minWidth: '200px',
                                        flexShrink: 0,
                                      }}
                                      onMouseEnter={() =>
                                        setHoveredAppointmentId(appointment.id)
                                      }
                                      onMouseLeave={() =>
                                        setHoveredAppointmentId(null)
                                      }
                                    >
                                      <div className="appointment-content-wrapper">
                                        {/* Top line with icon (left) and appointment number (right) */}
                                        <div className="appointment-header">
                                          <FontAwesomeIcon
                                            icon={appointment.statusIcon}
                                            className="status-icon"
                                            title={appointment.Status}
                                          />
                                          <span className="appointment-number">
                                            {appointment.content}
                                          </span>
                                        </div>

                                        {/* Customer name on a new line, truncated if it's too long */}
                                        <div className="customer-name">
                                          {displayName}
                                        </div>

                                        {/* Tooltip stays at the same level so it can appear on hover */}
                                        {hoveredAppointmentId ===
                                          appointment.id && (
                                          <div className="tooltip">
                                            <div>
                                              <strong>
                                                {appointment.CustName}
                                              </strong>
                                            </div>
                                            <div>
                                              <strong>Description: </strong>
                                              {appointment.AppDescription}
                                            </div>
                                            <div>
                                              <strong>Address: </strong>
                                              {appointment.CustAddress}
                                            </div>
                                            <div>
                                              <strong>Department: </strong>
                                              {appointment.BranchLocation}
                                            </div>
                                            <div>
                                              <strong>Status: </strong>
                                              {appointment.Status}
                                            </div>
                                            <div>
                                              <strong>Dispatch Notes: </strong>
                                              {appointment.DispatchNotes}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    {/* End of technician-appointments-container */}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <p>No appointments.</p>
        )}
      </DragDropContext>
    </div>
  );
}
